import "./Projects.css";
import placeholder from "./placeholder500x281.png";
import auJus from "./AuJus.webp";
import danimalClown from "./Danimalclown.webp";
import {Link} from 'react-router-dom';

const Projects = () => {
  return (
    <div>
      <div className="projectCard">
        <img src={auJus} alt="Aujus website homepage"></img>
        <div className="projectCard__textSection">
          <h2 className="projectCard__textSection-title">Au Jus</h2>
          <p className="projectCard__textSection-text">A website created for an up and coming restaurant in the greater Cleveland Ohio area. Having a menu that is easily accessible and guiding users to the online ordering page through our UX and UI design were the most important aspects for this project. </p>
          <a href="https://www.aujus-cle.com" target="_blank" className="btn projectCard__textSection-btn">View Site</a>
        </div>
      </div>
      <div className="projectCard">

      </div>
      <div className="projectCard">
        <img src={danimalClown} alt="DanimalClown"></img>
        <div className="projectCard__textSection">
          <h2 className="projectCard__textSection-title">Danimal Clown</h2>
          <p className="projectCard__textSection-text">A website created for Northeast Ohio's most popular experienced party clown. This project was done by creating a custom wordpress theme so the client could maintain the website without the help of developers in the future. Alot of time went into the design making the site look fun and professional and functionality so editing the site would be easy. </p>
          <a href="https://www.DanimalClown.com" target="blank" className="btn projectCard__textSection-btn">View Site</a>
        </div>
      </div>
      <div className="projectCard">

      </div>
    </div>
  );
};

export default Projects;
